import Header from "components/Header";
import Footer from "components/Footer";

export default {
  name: "MainLayout",
  components: {
    Header,
    Footer,
  },
  computed: {
    hasPageHeader() {
      return (
        this.$route.name !== "login" &&
        this.$route.name !== "change-password" &&
        this.$route.name !== "reset-password"
      );
    },
    pageBreadcrumbs() {
      const theRoutes = this.matchedRoutes()
        .filter(
          (item) => item.path !== "" && item.path !== "/" && item?.meta?.slug
        )
        .map(({ name, path, meta, regex }) => ({
            name: meta.label
              ? meta.label
              : meta.slug
              ? this.formatRouteName(meta.slug)
              : this.formatRouteName(name) || "", 
            path,
            meta,
            isCurrentPath: regex.test(this.$route.path),
          }));
      return theRoutes;
    },
  },
  methods: {
    sanitizeName(name) {
      const words = name.split("-").map(i => `${i.charAt(0, 1).toUpperCase()}${i.slice(1)}`);
      return words.join(" ");
    },
    matchedRoutes() {
      return this.$route.matched;
    },
    formatRouteName(routeName) {
      const splitName = routeName.split("-");

      if (splitName.length > 1) {
        let newRouteName = [];

        for (var name of splitName) {
          newRouteName.push(name.charAt(0).toUpperCase() + name.slice(1));
        }

        return newRouteName ? newRouteName.join(" ") : "";
      }

      return splitName[0]
        ? splitName[0].charAt(0).toUpperCase() + splitName[0].slice(1)
        : "";
    },
  },
};
